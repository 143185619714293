var swiper = new Swiper(".smiles-swiper", {
  slidesPerView: 3,
  spaceBetween: 30,
  pagination: {
    el: ".smiles-swiper-pagination",
    clickable: true,
  },
});

var swiper = new Swiper(".interier-swiper", {
  slidesPerView: 3,
  spaceBetween: 30,
  pagination: {
    el: ".interier-swiper-pagination",
    clickable: true,
  },
});


var swiper = new Swiper(".certificates-swiper", {
  slidesPerView: 5,
  spaceBetween: 20,
  pagination: {
    el: ".certificates-swiper-pagination",
    clickable: true,
  },
});